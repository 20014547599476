<template>
  <div>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-1">
      <div v-for="(item, index) in productList" :key="item.objectID" class="w-full hover:shadow-lg rounded pb-5">
        <item-list-view-2 :item="item" :inCart="isInCartTest(item._id)" :index="index"
          :superAdminCanAddToCart="superAdminCanAddToCart" @quantityChange="quantityChange">
          <template slot="action-buttons">
            <div class="w-full px-4" v-if="!isInCartTest(item._id)">
              <div @click="cartButtonClicked(item)" v-if="(item.canBuy || superAdminCanAddToCart) && item.isAvailable"
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333">
                <span class="text-base font-base">Add to Cart</span>
              </div>
            </div>

            <div v-if="!item.canBuy && !superAdminCanAddToCart" class="w-full px-4">
              <div
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333" @click="learnMore(item.supplier.supplierOnboardingLink)"
                v-if="item.supplier.supplierOnboardingLink">
                <span class="text-base font-base">Learn More</span>
              </div>
              <div v-else
                class="item-view-secondary-action-btn w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center"
                style="background-color: #0E4333" @click="popupActive = true">
                <span class="text-base font-base">Contact Fresh</span>
              </div>
            </div>

            <div v-if="!item.isAvailable && item.canBuy" class="w-full px-4">
              <div
                class="item-view-secondary-action-btn bg-gray-400 w-full py-2 rounded-lg items-center justify-center text-white cursor-pointer text-center">
                <span class="text-sm font-base">Out of Stock</span>
              </div>
            </div>
          </template>
        </item-list-view-2>
      </div>
    </div>
    <vs-popup class="holamundo" title="Supplier Account Required" :active.sync="popupActive">
      <div class="flex flex-no-wrap">
        <div class="w-1/6 flex items-center justify-center">
          <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
        </div>
        <div class="w-5/6">
          <ContactFresh :email="contactEmail" :number="contactNumber" />
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <vs-button class="mr-4 mb-4" @click="popupActive = false">
          I understand
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ContactFresh from "../../components/ContactFresh.vue";

// Utils
import {
  trackEcommerceEvent,
  formatPriceValue,
  VIEW_ITEM_LIST_EVENT,
  ADD_TO_CART_EVENT,
  getCurrency
} from '../../../utils/gtag'

export default {
  inject: ["getRoleRouteName"],

  components: {
    ItemListView2: () =>
      import("@/views/org-owner/ecommerce/ItemListView2.vue"),
    ContactFresh,
  },
  props: ["productList"],
  data() {
    return {
      data: {
        buisness_builder_products: [],
      },
      canAdd: false,
      clinicId: "",
      popupActive: false,
      contactEmail: this.contactFresh("email"),
      contactNumber: this.contactFresh("number"),
    };
  },
  computed: {
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    },
    superAdminCanAddToCart() {
      return this.isLoggedInSuperAdmin && !!this.canAdd
    }
  },
  methods: {
    ...mapActions("ecommerce", ["getHomepageProductsByIds", "productFilterListing"]),
    ...mapActions("ecommerce", ["getCartItemsFromApi"]),
    ...mapActions("admin", ["getSettingsByKey"]),

    learnMore(url) {
      window.open(url)
    },
    quantityChange(data) {
      if (this.productList[data.index].type !== "basic") {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: this.productList[data.index]._id,
            },
          })
          .catch(() => { });
        return false;
      }
      this.productList[data.index].selectedQuantity =
        data.quantity;
    },
    async cartButtonClicked(item) {
      if (item.tcc || item.type === "bundle" || item.type === 'lead') {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: item._id,
            },
          })
          .catch(() => { });
        return false;
      }

      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (this.$store.state.clinicId === null) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Please Select a clinic.",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      const totalCartAndSelectedQty = cartQuantity + item.selectedQuantity;
      if (
        item.inventory.maxQuantity !== null &&
        item.inventory.maxQuantity !== 0
      ) {
        if (
          item.selectedQuantity > item.inventory.maxQuantity ||
          totalCartAndSelectedQty > item.inventory.maxQuantity
        ) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }

      this.additemInCart(item, "increment");
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    additemInCart(data, type) {
      let minQty = 1;
      if (data.inventory.requiredQuantity > 1) {
        minQty = data.inventory.requiredQuantity;
      }
      let item = { data: data, type: type, minQty: minQty };

      this.$vs.loading();

      item.data.shipping.shippingFee = item.data.supplier.deliveryDetails
        ? item.data.supplier.deliveryDetails.deliveryFee
        : 0;

      this.$store
        .dispatch("eCommerce/additemInCart", item)
        .then((response) => {

          trackEcommerceEvent(ADD_TO_CART_EVENT, {
            currency: getCurrency(),
            value: formatPriceValue(item.data.price),
            items: [{
              item_id: item.data._id,
              item_name: item.data.name,
              price: formatPriceValue(item.data.price),
              quantity: minQty
            }]
          })

          this.getCartItemsFromApi(), this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: "Product added",
            text: "Product was successfully added to your cart.",
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$vs.loading.close();
          const supplierErrMsg = "Sorry! You need to setup a supplier account to purchase this product."
          if (err.data && err.data.message && err.data.message === supplierErrMsg) {
            this.productList.find(p => p._id.toString() === data._id.toString()).canBuy = false;
            this.$vs.notify({
              color: 'danger',
              title: 'Add to cart failed',
              text: supplierErrMsg,
              time: 5000,
            });
          }
        });
    },
    contactFresh(contact) {
      if (process.env.VUE_APP_REGION !== "AU") {
        if (contact === "email")
          return (this.contactEmail = "hello@freshclinics.com");
        if (contact === "number")
          return (this.contactNumber = "+1 888-925-1462");
      }

      if (contact === "email")
        return (this.contactEmail = "info@freshclinics.com.au");
      if (contact === "number") return (this.contactNumber = "1300 375 646");
    },
    async fetchCanAddSettingKey() {
      try {
        const { data } = await this.getSettingsByKey("superAdminCanAdd")

        if (data.data) this.canAdd = data.data.value === "yes"
      } catch (err) {
        console.log(' ----> error fetching can-add setting key ')
        console.log(err)
      }
    }
  },
  created() {
    this.clinicId = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (this.isLoggedInSuperAdmin) this.fetchCanAddSettingKey()
  },
  watch: {
    "$store.state.clinicId": function (val) {
      this.clinicId = val;
    },

  },
};
</script>

<style lang="scss" scoped>
.items-wrapper {
  >div {
    border: 1px solid #eaeaea;
  }
}
</style>
